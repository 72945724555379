import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, NamedLink } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import IconCard from '../SavedCardDetails/IconCard/IconCard';
import css from './SecondaryBar.module.css';

const rightSideButtons = [
  {
    label: 'How it Works',
    name: 'how-it-works',
  },
  {
    label: 'How to Sell',
    name: 'how_to_sell',
    tooltipLinks: [
      { label: 'Sell Yourself', name: 'how_to_sell' },
      { label: 'Consignment and Clear Out', name: 'selling_services' },
    ],
  },
  {
    label: 'About',
    name: 'about',
  },
  {
    label: 'Help',
    name: 'help',
  },
];

function SecondaryBar(props) {
  const config = useConfiguration();
  const categories = config?.categoryConfiguration?.categories ?? [];
  const { onOpenMobileMenu, currentUser } = props;
  const [activeTooltip, setActiveTooltip] = useState(null);

  if (categories.length === 0) {
    return null;
  }

  const { isAuthenticated } = useSelector(state => state).auth;

  const handleMouseEnter = index => {
    setActiveTooltip(index);
  };

  const handleMouseLeave = () => {
    setActiveTooltip(null);
  };

  return (
    <div className={css.secondaryTopbar}>
      <div className={css.desktopLeft}>
        {[...categories, { id: 'sale', name: 'Sale' }].map((category, index) => {
          return (
            <div key={index}>
              {category.id === 'gifts' ? (
                <NamedLink name="CMSPage" params={{ pageId: 'gifts' }}>
                  {category.name}
                </NamedLink>
              ) : (
                <NamedLink
                  name="SearchPage"
                  to={{
                    search:
                      category.id === 'sale'
                        ? `?pub_discountAvailable=yes&sort=pub_discountTimestamp`
                        : `?pub_categoryLevel1=${category.id}`,
                  }}
                >
                  {category.name}
                </NamedLink>
              )}
            </div>
          );
        })}
      </div>
      <div className={css.desktopRight}>
        {rightSideButtons.map((button, index) => {
          return button.name === 'how_to_sell' ? (
            <div
              key={index}
              className={css.rightButtonTooltip}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <span style={{}}>{button.label}</span>
              <div
                className={`${css.tooltipContent} ${activeTooltip ? css.active : ''}`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className={css.tooltipLinks}>
                  {button.tooltipLinks.map((link, linkIndex) => (
                    <NamedLink
                      key={linkIndex}
                      name="CMSPage"
                      params={{ pageId: link.name }}
                      className={css.tooltipLink}
                    >
                      {link.label}
                    </NamedLink>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div key={index} onMouseEnter={handleMouseLeave}>
              <NamedLink name="CMSPage" params={{ pageId: button.name }}>
                {button.label}
              </NamedLink>
            </div>
          );
        })}
      </div>
      <div className={css.mobileLeft}>
        <NamedLink name="NewListingPage" className={css.listItemButton}>
          List an Item
        </NamedLink>
      </div>
      <div className={css.mobileRight}>
        <NamedLink name="FavoriteListingsPage">
          <IconCard brand="heart" />
        </NamedLink>
        <NamedLink name="InboxBasePage">
          <IconCard brand="mail" />
        </NamedLink>
        {isAuthenticated ? (
          <div onClick={onOpenMobileMenu}>
            <Avatar user={currentUser} disableProfileLink />
          </div>
        ) : (
          <div className={css.loginSignupWrapper}>
            <NamedLink name="LoginPage">Login</NamedLink>
            <span>|</span>
            <NamedLink name="SignupPage">Signup</NamedLink>
          </div>
        )}
      </div>
    </div>
  );
}
export default SecondaryBar;

import React, { useState } from 'react';
import { IconArrowHead, NamedLink } from '../../../../components';
import { useConfiguration } from '../../../../context/configurationContext';
import css from './CategoryModal.module.css';

const data = [
  {
    id: 1,
    label: 'How it works',
    path: 'how-it-works',
  },
  {
    id: 2,
    label: 'How to sell',
    path: 'how_to_sell',
    tooltipLinks: [
      { label: 'Sell Yourself', name: 'how_to_sell' },
      { label: 'Consignment and Clear Out', name: 'selling_services' },
    ],
  },
  {
    id: 3,
    label: 'About',
    path: 'about',
  },
  {
    id: 4,
    label: 'Help',
    path: 'help',
  },
];

function CategoryModal() {
  const config = useConfiguration();
  const categories = config?.categoryConfiguration?.categories ?? [];
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  return (
    <div className={css.root}>
      <div className={css.content}>
        <span className={css.greeting}>Browse categories</span>

        <div className={css.accountLinksWrapper}>
          {[...categories, { id: 'sale', name: 'Sale' }].map((category, index) => {
            return (
              <div key={index}>
                {category.id === 'gifts' ? (
                  <NamedLink name="CMSPage" params={{ pageId: 'gifts' }} className={css.inbox}>
                    {category.name}
                  </NamedLink>
                ) : (
                  <NamedLink
                    name="SearchPage"
                    className={css.inbox}
                    to={{
                      search:
                        category.id === 'sale'
                          ? `?pub_discountAvailable=yes&sort=pub_discountTimestamp`
                          : `?pub_categoryLevel1=${category.id}`,
                    }}
                  >
                    {category.name}
                  </NamedLink>
                )}
              </div>
            );
          })}
        </div>

        <div className={css.dataLinksWrapper}>
          {data.map((elm, index) => {
            return elm.path === 'how_to_sell' ? (
              <div key={index} style={{ cursor: 'pointer' }}>
                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <span className={css.greeting}>{elm.label}</span>
                  <IconArrowHead
                    direction={isDropdownOpen ? 'up' : 'down'}
                    size="small"
                    className={css.arrow}
                  />
                </div>
                {isDropdownOpen && (
                  <div className={css.dropdownContent}>
                    {elm.tooltipLinks.map((link, index) => (
                      <div key={index}>
                        <NamedLink
                          name="CMSPage"
                          className={css.dropdownItem}
                          params={{ pageId: link.name }}
                        >
                          {link.label}
                        </NamedLink>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div key={index}>
                <NamedLink name="CMSPage" className={css.greeting} params={{ pageId: elm.path }}>
                  {elm.label}
                </NamedLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryModal;
